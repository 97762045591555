import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import { UserContext} from "./context/UserContext";

const Polityka = React.lazy(() => import("./pages/polityka/Index"));
const Regulamin = React.lazy(() => import("./pages/Regulamin/Index"));
const LandingPage = React.lazy(() => import("./pages/LandingPage/Index"));
const MainPage = React.lazy(() => import("./pages/MainPage/Index"));
const SzkolaLetnia = React.lazy(() => import("./pages/SzkolaLetnia/Index"));
const Szkolenie2Stopien = React.lazy(() =>
  import("./pages/Szkolenie2Stopien/Index")
);
// const Szkolenie1Stopien = React.lazy(() => import("./pages/Szkolenie1Stopien/Index"));
const Edukacja = React.lazy(() => import("./pages/Edukacja/Index"));
const Artykuly = React.lazy(() => import("./pages/Blog/Index"));
const Organizatorzy = React.lazy(() => import("./pages/Organizatorzy/Index"));
const Wpis = React.lazy(() => import("./pages/Wpis/Index"));
const Prelegenci = React.lazy(() => import("./pages/Prelegenci/Index"));
const Prelegent = React.lazy(() => import("./pages/Prelegent/Index"));
const Login = React.lazy(() => import("./pages/Login/Index"));
const Register = React.lazy(() => import("./pages/Register/Index"));
const ForgetPassword = React.lazy(() => import("./pages/ForgetPassword/Index"));

const Kursy = React.lazy(() => import("./pages/Dynamic/Kursy/Index"));
const Kurs = React.lazy(() => import("./pages/Dynamic/Kurs/Index"));
const KursPytania = React.lazy(() =>
  import("./pages/Dynamic/KursPytania/Index")
);
const KursIntro = React.lazy(() => import("./pages/Dynamic/KursIntro/Index"));
const KursFilm = React.lazy(() => import("./pages/Dynamic/KursFilm/Index"));
const KursEnd = React.lazy(() => import("./pages/Dynamic/KursEnd/Index"));
const Certyfikaty = React.lazy(() =>
  import("./pages/Dynamic/Certyfikaty/Index")
);
const DodatkoweMaterialy = React.lazy(() =>
  import("./pages/Dynamic/DodatkoweMaterialy/Index")
);
const Newsy = React.lazy(() => import("./pages/Dynamic/Newsy/Index"));
const Profil = React.lazy(() => import("./pages/Dynamic/Profil/Index"));

const AdminKursy = React.lazy(() => import("./pages/Admin/KursyAdmin/Index"));
const DodajKurs = React.lazy(() => import("./pages/Admin/DodajKurs/Index"));
const KursSekcje = React.lazy(() => import("./pages/Admin/KursSekcje/Index"));
const EdytujKurs = React.lazy(() => import("./pages/Admin/EdytujKurs/Index"));
const EdytujSekcje = React.lazy(() =>
  import("./pages/Admin/EdytujSekcje/Index")
);
const AdminUzytkownicy = React.lazy(() =>
  import("./pages/Admin/Uzytkownicy/Index")
);
const AdminEdytujUzytkownika = React.lazy(() =>
  import("./pages/Admin/EdytujUzytkownika/Index")
);
const AdminPrelegenci = React.lazy(() =>
  import("./pages/Admin/Prelegenci/Index")
);
const AdminEdytujPrelegenta = React.lazy(() =>
  import("./pages/Admin/EdytujPrelegenta/Index")
);
const AdminDodajPrelegenta = React.lazy(() =>
  import("./pages/Admin/DodajPrelegenta/Index")
);
const AdminWpisy = React.lazy(() => import("./pages/Admin/Wpisy/Index"));
const AdminDodajWpis = React.lazy(() =>
  import("./pages/Admin/DodajWpis/Index")
);
const AdminEdytujWpis = React.lazy(() =>
  import("./pages/Admin/EdytujWpis/Index")
);
const AdminDodatkowePliki = React.lazy(() =>
  import("./pages/Admin/DodatkowePliki/Index")
);
const AdminDodajPlik = React.lazy(() =>
  import("./pages/Admin/DodajPlik/Index")
);
const AdminEdytujPlik = React.lazy(() =>
  import("./pages/Admin/EdytujPlik/Index")
);
const EdytujKursIntro = React.lazy(() =>
  import("./pages/Admin/EdytujKursIntro/Index")
);

function App() {
  const authCtx = useContext(UserContext);
  console.log(authCtx);
  return (
    <Suspense fallback={<p> </p>}>
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<p> </p>} />
        <Routes>
          <Route index element={<MainPage />} />
          <Route exact path="/polityka-prywatnosci" element={<Polityka />} />
          <Route exact path="/regulamin" element={<Regulamin/>} />
          <Route exact path="/szkola-letnia" element={<SzkolaLetnia />} />
          <Route
            exact
            path="/szkolenie-2-stopnia"
            element={<Szkolenie2Stopien />}
          />
          <Route exact path="/szkolenie-1-stopnia" element={<LandingPage />} />
          <Route exact path="/edukacja-ekonomiczna" element={<Edukacja />} />
          <Route exact path="/artykuly" element={<Artykuly />} />
          <Route exact path="/organizatorzy" element={<Organizatorzy />} />
          <Route exact path="/artykul/:id/:slug" element={<Wpis />} />
          <Route exact path="/prelegenci" element={<Prelegenci />} />
          <Route exact path="/prelegent/:id/:slug" element={<Prelegent />} />
          <Route exact path="/zaloguj-sie" element={<Login />} />
          <Route exact path="/reset-hasla" element={<ForgetPassword />} />
          <Route exact path="/zarejestruj-sie" element={<Register />} />

          {authCtx.isLoggedIn && (
                <>
                <Route exact path="/panel/kursy" element={<Kursy />} />
          <Route exact path="/panel/kursy/:id" element={<Kurs />} />
          <Route exact path="/panel/kursy/:id/intro" element={<KursIntro />} />
          <Route
            exact
            path="/panel/kursy/:id/pytania/:step"
            element={<KursPytania />}
          />
          <Route
            exact
            path="/panel/kursy/:id/film/:step"
            element={<KursFilm />}
          />
          <Route exact path="/panel/kursy/:id/dziekuje" element={<KursEnd />} />
          <Route exact path="/panel/certyfikaty" element={<Certyfikaty />} />
          <Route exact path="/panel/newsy" element={<Newsy />} />
          <Route exact path="/panel/profil" element={<Profil />} />
          <Route
            exact
            path="panel/dodatkowe-materialy"
            element={<DodatkoweMaterialy />}
          />
          <Route exact path="/admin/kursy" element={<AdminKursy />} />
          <Route exact path="/admin/dodaj-kurs" element={<DodajKurs />} />
          <Route
            exact
            path="/admin/kurs-sekcje/:course_id/:step_id"
            element={<KursSekcje />}
          />
          <Route
            exact
            path="/admin/edytuj-kurs/:course_id/intro"
            element={<EdytujKursIntro />}
          />
          <Route
            exact
            path="/admin/edytuj-kurs/:course_id"
            element={<EdytujKurs />}
          />
          <Route
            exact
            path="/admin/edytuj-sekcje/:course_id/:step_id"
            element={<EdytujSekcje />}
          />
          <Route
            exact
            path="/admin/uzytkownicy"
            element={<AdminUzytkownicy />}
          />
          <Route
            exact
            path="/admin/uzytkownicy/:user_id"
            element={<AdminEdytujUzytkownika />}
          />
          <Route exact path="/admin/prelegenci" element={<AdminPrelegenci />} />
          <Route
            exact
            path="/admin/prelegenci/:prelegent_id"
            element={<AdminEdytujPrelegenta />}
          />
          <Route
            exact
            path="/admin/prelegenci/dodaj"
            element={<AdminDodajPrelegenta />}
          />
          <Route exact path="/admin/newsy" element={<AdminWpisy />} />
          <Route exact path="/admin/newsy/dodaj" element={<AdminDodajWpis />} />
          <Route
            exact
            path="/admin/newsy/:article_id/:article_slug"
            element={<AdminEdytujWpis />}
          />
          <Route
            exact
            path="/admin/dodatkowe-pliki"
            element={<AdminDodatkowePliki />}
          />
          <Route exact path="/admin/dodaj-plik" element={<AdminDodajPlik />} />
          <Route
            exact
            path="/admin/dodatkowe-pliki/:plik_id"
            element={<AdminEdytujPlik />}
          />
                </>
          )}
          


          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
